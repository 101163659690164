/**
 * Function to set the title of the page.
 * @param title: The title of the page to set
 */
export const setTitle = (title) => window.document.title = title;

export const setFavicon = (icon) => document.querySelector('link[rel=icon]').href = icon;

/**
 * Function to redirect the user to a path
 * @param path: The path that the user have to be sent
 */
export const redirect = (path) => window.location.pathname = path;

/**
 * Function to return a string with a capitalized letter, at the given index
 * @param text: The string to capitalize
 * @param index: The index of the letter to capitalize
 * @return {string}: The final string with the capitalized letter
 */
export const capitalizeLetter = (text, index) =>
    text.slice(0, index)
    + text.charAt(index).toUpperCase()
    + text.slice(index + 1);

export const copyText = (text) => navigator.clipboard.writeText(text);

export const isNotClickable = (condition) => condition ? 'no-clickable' : 'link';

const colors = new Map();
colors.set("0", "BLACK");
colors.set("1", "DARK_BLUE");
colors.set("2", "DARK_GREEN");
colors.set("3", "DARK_AQUA");
colors.set("4", "DARK_RED");
colors.set("5", "DARK_PURPLE");
colors.set("6", "GOLD");
colors.set("7", "GRAY");
colors.set("8", "DARK_GRAY");
colors.set("9", "BLUE");
colors.set("a", "GREEN");
colors.set("b", "AQUA");
colors.set("c", "RED");
colors.set("d", "PINK");
colors.set("e", "YELLOW");
colors.set("f", "WHITE");

export default colors;

export const teams = {
    1: { color: "#FF5555", name: "RED" },
    2: { color: "#0000AA", name: "BLUE" },
    3: { color: "#55FF55", name: "GREEN" },
    4: { color: "#FFFF55", name: "YELLOW" },
    5: { color: "#00AAAA", name: "AQUA" },
    6: { color: "#FFFFFF", name: "WHITE" },
    7: { color: "#FF55FF", name: "PINK" },
    8: { color: "#555555", name: "GRAY" }
};