import React from "react";

import "./error.scss";

export default function Error() {
    return (
        <div className="error">
            <h1>404</h1>
            <h2>Pagina non trovata!</h2>
        </div>
    )
}