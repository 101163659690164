import React from 'react';

import Leaderboard from "../../components/leaderboard/Leaderboard";

function CoralCupLeaderboard() {
    return (
        <Leaderboard type={ 'coralcup' } />
    );
}

export default CoralCupLeaderboard;