import React, {useCallback} from 'react';

import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

function CustomParticles() {
    const particlesInit = useCallback(async (engine) => await loadFull(engine), []);

    return (
        <Particles  id={ 'tsparticles' }
                    init={ particlesInit }
                    options={{
                        preset: "stars",
                        background: { opacity: 0 },
                        style: { height: "300px", position: "absolute" },
                        autoPlay: true,
                        particles: {
                            opacity: {
                                random: true,
                                value: { min: 0.3, max: 1.5 },
                                animation: { enable: true, speed: 0.5 }
                            },
                            color: { value: "#FFFFFF" },
                            size: { random: true },
                            move: { enable: true, speed: 0.05 }
                        }
                    }}
                    style={{
                        width: "100%", height: "100%",
                        position: "relative", zIndex: 0,
                        top: 0, left: 0,
                        backgroundColor: "#000000",
                        pointerEvents: "none",
                    }}/>
    );
}

export default CustomParticles;