import React from "react";
import "./style.scss";

export default function Position({ position, first, second }) {

    return (
            <div className={"skin " + (position === 1 ? "first-skin" : position === 2 ? "second-skin" : "third-skin")}>

                <div className="status">
                    <h1 className={ position === 1 ? "gold" : position === 2 ? "silver" : "bronze" }> { position }° POSTO </h1>
                </div>

                <div className="img-container">
                    <img src={`https://minotar.net/armor/body/${first}/150.png`} alt="Skin" />
                    <img src={`https://minotar.net/armor/body/${second}/150.png`} alt="Skin" />
                </div>

            </div>
    )
}

