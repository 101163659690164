import React from "react";

import "./style.scss";

function Column({ title, description }) {
    return (
        <div className={ 'column center' }>
            <h1>{ title }</h1>
            { description }
        </div>
    )
}

export default function Footer() {
    return (
        <div className={ 'footer box' }>
            <Column title={ 'Chi Siamo' }
                    description={
                        <p>
                            CoralMC nasce nel 2020 in risposta alle restrizioni previste
                            dal lockdown, dando agli amanti di Minecraft la possibilità
                            di divertirsi in un nuovo ecosistema che andasse oltre le
                            quattro mura di casa. Adesso CoralMC è il server più giocato
                            in Italia e tra i più amati per coloro a cui piace della
                            sana competizione.
                        </p>
                    } />

            <Column title={ 'Link Utili' }
                    description={
                        <div className={ 'links center' }>
                            <a href="https://store.coralmc.it">
                                Store
                            </a>
                            <div className="separator"></div>
                            <a href="httpsdiscord.com/invite/MGW42TXHBd">
                                Discord
                            </a>
                            <div className="separator"></div>
                            <a href="httpst.me/CoralMC">
                                Telegram
                            </a>
                        </div>
                    } />

            <Column title={ 'Supportaci' }
                    description={
                        <>
                            <p>
                                Regalati un'emozione unica supportando il tuo server
                                preferito!
                            </p>
                            <a className={ 'button center' } href="httpsstore.coralmc.it/">Store</a>
                        </>
                    } />
        </div>
    )
}