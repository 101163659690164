import React from 'react';

function Stat({ type, value }) {
    return (
        <div className="stat">
            <h1 className="type">{ type }</h1>
            <h1 className="value">{ value }</h1>
        </div>
    )
}

export default Stat;