import React from "react";

import moment from "moment";
import { capitalizeLetter } from "../../../utils/utils";

export default function Game({ game }) {
    const winnerColor = { color: (
        (game.end === null) ? "#EA8C55" :
        (game.winner === game.player_team) ? "#38d738" : "#DD2F2FFF")
    };

    return (
        <a href={ `/game/${ game.id }` }>
            <div className="player-game line-bottom">
                <h1 className="result"
                    style={ winnerColor }>
                    {
                        (game.end === null) ? "In corso" :
                        (game.winner === game.player_team) ? "Vittoria" : "Perso"
                    }
                </h1>
                <h1 className="server">BW{ game.server }</h1>
                <h1 className="start">
                    { moment(game.start).format("DD/MM/YYYY") }
                    <br />
                    { moment(game.start).format("HH:mm:ss") }
                </h1>
                <h1 className="end">
                    { 
                        (game.end == null)
                        ? "-"
                        : (<>
                                { moment(game.end).format("DD/MM/YYYY") }
                                <br/>
                                { moment(game.end).format("HH:mm:ss") }
                            </>)
                    }
                </h1>
                <h1 className="arena">
                    { game.arena_name }
                </h1>
                <h1 className="type">
                    { capitalizeLetter(game.type_name.toLowerCase(), 0) }
                </h1>
                <h1 className={ "team " + ((game.winner_team) ? game.winner_team : "") }>
                    { (game.winner_team) ? capitalizeLetter(game.winner_team.toLowerCase(), 0) : "-"}
                </h1>
            </div>
        </a>
    )
}