import React from "react";
import "./style.scss";
import Position from "./Position";

export default function Podium({ data }) {

    return (
        <div className={"podium center"}>

            {
                data.slice(0, 3).map((player, index) =>
                    <Position
                        position={ index + 1 }
                        first={ player.team_members[0] }
                        second={ player.team_members[1] }
                    />
                )
            }

        </div>
    )
}

