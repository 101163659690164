import React from "react";

import "./style.scss";

import { useState, useEffect } from "react";

import { IoIosArrowBack } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";

import Game from "./Game";
import Loading from "../../../components/loading/Loading";
import Error from "../../../components/error/Error";
import { setFavicon, setTitle } from "../../../utils/utils";
import Stat from "../../../components/Stat";
import BWPlayer from "./BWPlayer";
import Tag from "../../../components/tag/Tag";

export default function BWTeam() {
    const [data, setData] = useState(null);
    const teamId = window.location.pathname.split("/")[2];

    useEffect(() => {
        const api = `${process.env.REACT_APP_PROXY}/api/coralcup/team/${teamId}`;
        setData(null);

        fetch(api, { method: "POST" })
            .then(result => result.json())
            .then(result => {
                setData(result);

                const players = result.team.players;

                const username = players[0].username + ", " + players[1].username;

                setFavicon(`https://cravatar.eu/helmavatar/${username}/190.png`);
                setTitle(`${username} | Bedwars`);
            }).catch(() => setData({ error: '404' }));
    }, [])

    return (
        <>
            <Loading loaded={data != null} />

            {
                (data == null || data.error === "404")
                    ? <Error />
                    : <div className="bw-player-component center">
                        <div className="player">
                            <div className="top line-bottom">
                                <div className="left">
                                    <h1 className="icon">
                                        <FaUserAlt size={20} />
                                    </h1>
                                    <h1>
                                        <div className="name center">
                                            <Tag text={ data.team.players[0].rank } />
                                            <Tag text={ data.team.players[1].rank } />
                                            <span className={'space'}>{data.team.players[0].username}</span>
                                            <span className={'space'}>{data.team.players[1].username}</span>
                                        </div>
                                    </h1>
                                </div>

                                <a className="back-ref" href="/">
                                    <div className="back">
                                        <IoIosArrowBack size={20} />
                                    </div>

                                    <h1 className="text"> Torna alla classifica </h1>
                                </a>

                            </div>

                            <div className="container">
                                <div className="stats">

                                    <div className="skin-container">
                                        <div className="skin">

                                            <div className="img-container">
                                                <img src={`https://minotar.net/armor/body/${data.team.players[0].username}/300.png`} alt="Skin" />
                                                <img src={`https://minotar.net/armor/body/${data.team.players[1].username}/300.png`} alt="Skin" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="stats-box">
                                        <div className="stats">

                                            <div className="line">
                                                <Stat type={'Punti'} value={data.team.points} />
                                                <Stat type={'Partite'} value={data.team.matches.length} />
                                                <Stat type={'Vittorie'} value={data.team.wins} />
                                            </div>

                                            {/*

                                            <div className="line">
                                                <Stat type={'Morti'} value={data.player.deaths} />
                                                <Stat type={'K/D'} value={(data.player.deaths !== 0) ? data.player.kd.toFixed(2) : "0"} />
                                                <Stat type={'FK/D'} value={(data.player.deaths !== 0) ? data.player.fkd.toFixed(2) : "0"} />
                                                <Stat type={'Ucc. Finali'} value={data.player.final_kills} />
                                            </div>

                                            <div className="line">
                                                <Stat type={'Letti Rotti'} value={data.player.beds_broken} />
                                                <Stat type={'Vittorie'} value={data.player.wins} />
                                                <Stat type={'Partite'} value={data.player.played} />
                                                <Stat type={'Elo'} value={data.player.elo} />
                                            </div>

                                            <div className="line last">
                                                <Stat type={'Winstreak'} value={data.player.winstreak} />
                                                <Stat type={'Max Winstreak'} value={data.player.h_winstreak} />
                                                <Stat type={'Clan'} value={data.player.clan} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="players-box">
                                    <div className="players">
                                        <div className="player-info line-bottom">
                                            <h1 className="name">Nome</h1>
                                            <h1 className="level">Livello</h1>
                                            <h1 className="kills">Uccisioni</h1>
                                            <h1 className="finalKills">Ucc. Finali</h1>
                                            <h1 className="deaths">Morti</h1>
                                            <h1 className="kd">K/D</h1>
                                            <h1 className="fkd">FK/D</h1>
                                        </div>

                                        { data.team.players.map(player => <BWPlayer player={ player } />) }
                                    </div>
                                </div>

                                <div className="games-box">
                                    <div className="games">
                                        <div className="game-info line-bottom">
                                            <h1 className="result">Risultato</h1>
                                            <h1 className="server">Server</h1>
                                            <h1 className="start">Inizio</h1>
                                            <h1 className="end">Fine</h1>
                                            <h1 className="arena">Arena</h1>
                                            <h1 className="type">Tipo</h1>
                                            <h1 className="team">Vincitori</h1>
                                        </div>

                                        { data.team.matches.map((game, index) => <Game key={index} game={game} />) }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
            }
        </>
    )
}
