import React from "react";

export default function BWPlayer({ player }) {

    return (
        <div className="player-game line-bottom">

            <h1 className="name">
                <img className="player-skin none-select"
                     src={ `https://cravatar.eu/helmavatar/${player.username}/190.png` }
                     alt="Skin" />

                { player.username }
            </h1>

            <h1 className="level"> { player.stars } </h1>
            <h1 className="kills"> { player.kills } </h1>
            <h1 className="finalKills"> { player.final_kills } </h1>
            <h1 className="deaths"> { player.deaths }</h1>
            <h1 className="kd"> { player.kd.toFixed(2) } </h1>
            <h1 className="fkd"> { player.fkd.toFixed(2) } </h1>
        </div>
    )
}