import React from "react";
import './style.scss';

import colors from "../../utils/utils";

export default function Tag({ text } ) {
    if (text == null) return null;

    function convertColor(text) {
        text = text.replace("&l", "");
        let args = text.split("&");
        if (text[0] === "&") args.shift();
        const result = [];
        let firstColor = colors.get(args[0][0]);

        if (args.length === 0) return text;

        for (let i = 0; i < args.length; i++) {
            const color = colors.get(args[i][0]);

            if (i === 0 && (text[0] !== "&" || !color || args[i].length === 1))
                result.push(args[i]);
            else result.push(args[i].slice(1));
        }

        return (
            <span className={ 'tag center BG-' + firstColor }>
                { result.join("") }
            </span>
        );
    }

    return convertColor(text);
}