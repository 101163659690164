import React, {useEffect, useState} from 'react';
import "./style.scss";

import { HiMenu } from "react-icons/hi";

import Menu from './menu/Menu';

import logo from "../../imgs/logo.png";
import {copyText} from "../../utils/utils";
import CustomParticles from "./CustomParticles";

function Info({ action, text, value, type, left }) {
    return (
        <div className={ 'info center ' + (left ? 'left' : '') }
             onClick={ () => action() }>
            <div className={ 'txt center' }>
                <small>{ text }</small>
                <h1>{ value }</h1>
            </div>
            <div className={ 'icon center' }>
                <span className={ 'mdi-25px mdi-' + type }></span>
            </div>
        </div>
    )
}

export default function Header() {
    const ip = 'play.coralmc.it';
    const discord = '705161910370173033';

    const [menuOpen, setMenuOpen] = useState(null);
    const [ipButton, setIpButton] = useState('Premi per Copiare L\'IP');
    const [dsUsers, setDsUsers] = useState('0');
    const [mcUsers, setMcUsers] = useState('0');

    const copy = () => {
        copyText(ip);

        const initialText = ipButton;
        setIpButton('IP Copiato');

        setTimeout(() => setIpButton(initialText), 3000);
    }

    window.onclick = (event) => {
        const tagName = event.target.tagName;
        const className = event.target.className;

        if (menuOpen && (tagName !== 'svg' && tagName !== 'path' && className !== 'menu'))
            setMenuOpen(false);
    }

    useEffect(() => {
        fetch(`https://mcapi.us/server/status?ip=${ip}`)
            .then(result => result.json())
            .then(result => setMcUsers(result.players.now))
        fetch(`https://discordapp.com/api/guilds/${discord}/widget.json`)
            .then(result => result.json())
            .then(c => setDsUsers(c.presence_count));
    }, []);

    return (
        <>
            <Menu open={ menuOpen } />

            <div className={ 'header center' }>
                <CustomParticles />

                <div className={ 'glow' }></div>
                <div className={ 'bg' }></div>
                <div className={ 'bg colored' }></div>

                <div className={ 'top center no-select' }>
                    <Info text={ ipButton }
                          value={ mcUsers + ' Giocatori Online' }
                          type={ 'minecraft' }
                          action={ () => copy() } />

                    <a href="/" className={ 'logo' }>
                        <img src={ logo } alt="Logo" />
                    </a>

                    <Info text={ 'Entra nel nostro discord' }
                          value={ dsUsers + ' Utenti Online' }
                          type={ 'discord' }
                          action={ () => window.location = 'http://discord.coralmc.it' }
                          left />
                </div>

                <div className="nav-container">
                    <span className="curve shadow"></span>
                    <div className="curve before"></div>
                    <div className="curve after"></div>

                    <div className={ 'nav' }>
                        <div className={ 'inner center no-select' }>
                            <div className={ 'menu-button center' }>
                                <HiMenu onClick={ () => setMenuOpen(!menuOpen) } />
                            </div>

                            <a href="https://www.coralmc.it/">Home</a>
                            <a href="http://forum.coralmc.it">Forum</a>
                            <a className={ 'store' } href="https://store.coralmc.it">Store</a>
                            <a href="http://discord.coralmc.it">Discord</a>
                            <a href="https://www.coralmc.it/forum/staff">Staff</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}