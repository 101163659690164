import React from 'react';

import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { isNotClickable } from "../../utils/utils";

function Pages({ page, setPage, currentPage, pages }) {
    return (
        <>
            <div onClick={ () => setPage(0) }>
                <div className={ isNotClickable(currentPage - 1 < 0) }>
                    <MdSkipPrevious size={ 20 } />
                </div>
            </div>

            <div onClick={ () => (page - 1 >= 0) ? setPage(page - 1) : null }>
                <div className={ isNotClickable(currentPage - 1 < 0) }>
                    <IoIosArrowBack size={ 20 } />
                </div>
            </div>

            <div onClick={ () => (page + 1 <= pages - 1) ? setPage(page + 1) : null }>
                <div className={ isNotClickable(currentPage + 1 > pages - 1) }>
                    <IoIosArrowForward size={ 20 } />
                </div>
            </div>

            <div onClick={ () => setPage(pages - 1) }>
                <div className={ isNotClickable(currentPage + 1 > pages - 1) }>
                    <MdSkipNext size={ 20 } />
                </div>
            </div>
        </>
    )
}

export default Pages;