import React, { useEffect, useState } from "react";
import "./style.scss";

import { setTitle } from "../../utils/utils";

import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { FaSearch } from "react-icons/fa";

import Loading from "../loading/Loading";
import Error from "../error/Error";
import Podium from "../switch/Podium";
import Line from "./Line";
import Pages from "./Pages";

function InfoLine({ setOrder, order, setValue, setArrow, displayValue, value }) {
  const onOrder = (byValue) => {
    if (value !== byValue) setOrder("desc");
    else if (value === byValue && order === "desc") setOrder("asc");
    else setOrder("desc");

    setValue(byValue);
  };

  return (
    <th onClick={() => onOrder(value)} className="data">
      {displayValue}
      {setArrow(value)}
    </th>
  );
}

export default function Leaderboard({ type }) {
  const switchType = (bwValue, kitpvpValue, coralCupValue) => {
    if (type === "bedwars") return bwValue;
    else if (type === "kitpvp") return kitpvpValue;
    else if (type === "coralcup") return coralCupValue;
  };

  const [data, setData] = useState(null);

  const [order, setOrder] = useState("desc");
  const [value, setValue] = useState(switchType("level", "kills", "points"));
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    setData(null);

    const API =
      type === "bedwars"
        ? `${process.env.REACT_APP_PROXY}/api?page=${page}&value=${value}&order=${order}`
        : type === "kitpvp"
        ? `${process.env.REACT_APP_PROXY}/api/kitpvp?page=${page}&value=${value}&order=${order}`
        : `${process.env.REACT_APP_PROXY}/api/coralcup?page=${page}&value=${value}&order=${order}`;
    let api = API;

    if (searching) api = API + `&search=${search}`;

    fetch(api, { method: "POST" })
      .then((result) => result.json())
      .then((result) => {
        setData(result);

        if (type === "bedwars") setTitle(`Leaderboard | Bedwars`);
        else if (type === "kitpvp") setTitle(`Leaderboard | Kitpvp`);
        else if (type === "coralcup") setTitle(`Leaderboard | Coral Cup`);
      });
  }, [page, value, order, searching]);

  const setArrow = (newValue) => {
    if (value === newValue && order === "desc") return <TiArrowSortedDown />;
    else if (value === newValue && order === "asc")
      return <TiArrowSortedUp />;
    else return <TiArrowSortedUp className="hide" />;
  };

  const onSearch = async (event) => {
    setSearch(event.target.value);

    if (event.key !== "Enter") return;
    if (search.length === 0) return setSearching(false);
    if (search.length < 3 || search.length > 16)
      return alert(
        "Assicurati di aver inserito almeno 3 caratteri o meno di 16 caratteri!"
      );

    if (searching) await setSearching(false);
    await setSearching(true);
  };

  const infoProps = { setOrder, order, setValue, setArrow };

  return (
    <>
      <Loading loaded={data != null} />

      {data == null || data.error === "404" ? (
        <Error />
      ) : (
        <div className="bw-leaderboard-component center">
          <div className="c-con">

            { page === 0 ? <Podium data={ data.players }/> : null }

            <div className="leaderboard">
              <div className="search line-bottom">
                <div className="left">
                  <h1 className="search-icon">
                    <FaSearch size={20} />
                  </h1>
                  <input
                    onKeyUp={(event) => onSearch(event)}
                    className={"no-copy"}
                    placeholder="Cerca..."
                    type="text"
                  />
                </div>

                <div className="right">
                  <Pages
                    page={page}
                    setPage={setPage}
                    currentPage={data.currentPage}
                    pages={data.pages}
                  />
                </div>
              </div>

              <div className="scroll">
                <table className="players">
                  <thead>
                    <tr className="line-bottom index non-select">
                      <th className="player-index no-click"></th>
                      <th className="skin no-click no-shadow"></th>
                      <th className="data name no-click">Team</th>
                      <InfoLine
                        {...infoProps}
                        value={switchType("level", "kills", "games")}
                        displayValue={switchType("Livello", "Uccisioni", "Partite")}
                      />
                      <InfoLine
                        {...infoProps}
                        value={switchType("kills", "deaths", "points")}
                        displayValue={switchType("Uccisioni", "Morti", "Punti")}
                      />

                      {
                        switchType("kd", "kd", "") ?
                            <InfoLine
                                {...infoProps}
                                value={switchType("kd", "kd", "")}
                                displayValue={switchType("K/D", "K/D", "")}
                            /> : null
                      }

                      {
                        switchType("beds", "streak", "") ?
                            <InfoLine
                                {...infoProps}
                                value={switchType("beds", "streak", "")}
                                displayValue={switchType("Letti", "Streak", "")}
                            /> : null
                      }

                      {
                        switchType("wins", "topStreak", "") ?
                            <InfoLine
                                {...infoProps}
                                value={switchType("wins", "topStreak", "")}
                                displayValue={switchType("Vittorie", "Max Streak", "")}
                            /> : null
                      }
                      <th className="data no-click">{switchType("Clan", "Gang", "")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.players == null ? null : (
                      data.players.map((player, index) =>
                        type === "bedwars" ? (
                          <Line
                            key={index}
                            link={"/player/" + player.name}
                            pos={data.currentPage * 20 + index + 1}
                            name={player.name}
                            data={{
                              d1: player.livello,
                              d2: player.kills,
                              d3: !isNaN(parseInt(player.kd))
                                ? parseInt(player.kd).toFixed(2)
                                : "0.00",
                              d4: player.beds,
                              d5: player.wins,
                              d6: player.clan || "-",
                            }}
                          />
                        ) : type === "kitpvp" ? (
                          <Line
                            key={index}
                            link={"/kitpvp/player/" + player._id}
                            pos={data.positions[player._id] + 1}
                            online={data.onlines[player._id]}
                            name={player.displayName.slice(2)}
                            data={{
                              d1: player.kills,
                              d2: player.deaths,
                              d3:
                                player.deaths > 0
                                  ? (player.kills / player.deaths).toFixed(2)
                                  : "0.00",
                              d4: player.streak,
                              d5: player.topStreak,
                              d6: data.teams[player._id] || "-",
                            }}
                          />
                        ) : (
                          <Line
                            key={index}
                            link={"/team/" + player.id}
                            pos={ (data.currentPage * 20) + (index + 1) }
                            name={player.team_members}
                            data={{
                              d1: player.games,
                              d2: player.points
                            }}
                          />
                        )
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
