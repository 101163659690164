import React, { useEffect, useState } from "react";

import "./style.scss";

import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineVideogameAsset } from "react-icons/md";
import { setTitle } from "../../../utils/utils";

import Line from "./Line";
import Loading from "../../../components/loading/Loading";
import Error from "../../../components/error/Error";

function Info({ type, value, className }) {
    return (
        <div className="info">
            <h1 className="type">{ type }</h1>
            <h1 className={"value " + className}>{ value}</h1>
        </div>
    )
}

export default function Game() {
    const [data, setData] = useState(null);
    const [id] = useState(window.location.pathname.split("/")[2]);

    useEffect(() => {
        const api = `${process.env.REACT_APP_PROXY}/api/coralcup/game/${id}`;
        setData(null);

        fetch(api, { method: "POST" })
            .then(result => result.json())
            .then(result => {
                setData(result);
                setTitle(`Game #${id} | Bedwars`);
            })
    }, [id])

    return (
        <>
            <Loading loaded={ data != null } />

            {
                (data == null || data.error === "404")
                ? <Error/>
                : <div className="game-component center">
                    <div className="game">
                        <div className="topbar line-bottom">
                            <div className="left">
                                <h1 className="icon">
                                    <MdOutlineVideogameAsset/>
                                </h1>
                                <h1>Partita #{id}</h1>
                            </div>

                            <a className="back-ref" href="/">
                                <div className="back">
                                    <IoIosArrowBack size={20} />
                                </div>

                                <h1 className="text"> Torna alla classifica </h1>
                            </a>

                        </div>

                        <div className="container">
                            <div className="left">
                                <div className="line">
                                    <Info type={ 'Server' } value={ data.game.server } />
                                    <Info type={ 'Stato' } value={ data.game.status } />
                                    <Info type={ 'Tipo' } value={ "CoralCup" } />
                                </div>

                                <div className="line">
                                    <Info type={ 'Arena' } value={ data.game.arena } />
                                    <Info
                                        type={ 'Squadra Vincente' }
                                        value={ (data.game.status === "Conclusa") ? data.game.winner : "-" }
                                        className={ (data.game.status === "Conclusa") ? data.game.winner.toUpperCase() : "" }
                                    />
                                    <Info type={ 'Giocatori Totali' } value={ data.players.length } />
                                </div>

                                <div className="line last">
                                    <Info type={ 'Inizio' } value={ <>{ data.game.timeStart }<br/>{ data.game.dataStart }</>} />
                                    <Info type={ 'Durata' } value={ (data.game.status === "Conclusa") ? data.game.durationFormat : "-" } />
                                </div>
                            </div>

                            <div className="right">
                                <table id="myTable" className="players">
                                    <thead>
                                    <tr className="line-bottom none-select">
                                        <th className="skin"></th>
                                        <th className="team plus"></th>
                                        <th className="data name">Nome</th>
                                        <th className="data">K/D</th>
                                        <th className="data">Uccisioni</th>
                                        <th className="data">Morti</th>
                                        <th className="data">Letti</th>
                                        <th className="data">Uccisioni<br/>finali</th>
                                        <th className="data">Danno<br/>inflitto</th>
                                        <th className="data">Danno<br/>subito</th>
                                        <th className="data">Rating</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data.players.map((player, index) =>
                                            <Line key={ index }
                                                  link={ `/team/${player.coralcup_team}` }
                                                  player={ player }
                                                  winner={ data.game.winner } />
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}