import React from "react";
import { redirect } from "../../utils/utils";

export default function Line({ online, link, pos, name, data: { d1, d2, d3, d4, d5, d6 } }) {
    const status = { backgroundColor: (online) ? "#07e713" : "#FF4242" };

    // FIXME Works only if CoralCUP
    const fistName = name[0]
    const secondName = name[1]

    return (
        <>
            <tr onClick={ () => redirect(link) }
                className="link line-bottom">
                <td className="player-index">
                    #{ pos }
                </td>
                <td className="skin shadow non-select">
                    <div className="first-image">
                        <img src={ `https://cravatar.eu/helmavatar/${fistName}/190.png` } alt="Skin" />
                    </div>
                    <div className="second-image">
                        <img src={ `https://cravatar.eu/helmavatar/${secondName}/190.png` } alt="Skin" />
                    </div>
                    <div className="status non-select" style={ status }></div>
                </td>
                <td className="data name">
                    { name[0] + " " + name[1] }
                </td>
                <td className="data">
                    { d1 }
                </td>
                <td className="data">
                    { d2 }
                </td>
                <td className="data">
                    { d3 }
                </td>
                <td className="data">
                    { d4 }
                </td>
                <td className="data">
                    { d5 }
                </td>
                <td className="data">
                    { d6 }
                </td>
            </tr>
        </>
    )
}