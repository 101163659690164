import React, {useEffect, useState} from "react";

import './style.scss';

export default function Menu({ open }) {
    const [render, setRender] = useState(false);
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        if (open === null) return;

        setAnimation(open ? 'mOpen .5s ease-in-out' : 'mClose .5s ease-in-out');
        setRender(true);

        setTimeout(() => {
            setRender(open);
        }, 450)
    }, [open])

    if (!render) return null;

    return (
        <div style={ { animation } } className={ 'menu box' }>
            <div className={ 'title' }>Menu</div>
            <div className={ 'list center' }>
                <a href="client/src/components/header/menu/Menu">Home</a>
                <a href="https://www.coralmc.it/forum/index.php">Forum</a>
                <a href="client/src/components/header/menu/Menu">Store</a>
                <a href="client/src/components/header/menu/Menu">Discord</a>
                <a href="https://www.coralmc.it/forum/staff">Staff</a>
            </div>
        </div>
    )
}