import React from "react";
import './style.scss';

import {BrowserRouter, Route, Routes} from "react-router-dom";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Error from "./components/error/Error";

import CoralCupLeaderboard from "./pages/coralcup/CoralCupLeaderboard";
import BWTeam from "./pages/coralcup/team/BWTeam";
import Game from "./pages/coralcup/game/Game";


export default function App() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/" element={<CoralCupLeaderboard />} />
                {/* <Route path="/team/:username" element={<BWPlayer />} /> */ }
                <Route path="/team/:id" element={<BWTeam />} />
                <Route path="/game/:id" element={<Game />} />

                {/* Comment
                <<Route path="/kitpvp" element={<KitpvpLeaderboard />} />
                <Route path="/kitpvp/gang/:name" element={<Gang />} />
                <Route path="/kitpvp/player/:username" element={<KitpvpPlayer />} />

                <Route path="/coralcup" element={<CoralCupLeaderboard />} />
                */}

                <Route path="*" element={<Error />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}