import React from 'react';

import {redirect, teams} from "../../../utils/utils";
import { AiFillHeart, AiFillStar } from "react-icons/all";

function Data({ value, icon }) {
    return (
        <td className="data">
            { value }
            { icon ? <span className="icon center">{ icon }</span> : null }
        </td>
    )
}

export default function Line({ link, player, winner }) {
    const border = { border: "3px solid " + teams[player.team].color };
    const color = { color: teams[player.team].color };

    return (
        <tr className={ "line-bottom pointer " + ((teams[player.team].name === winner.toUpperCase()) ? "winner" : "") }
            onClick={ () => redirect(link) }>
            <td>
                <img className="skin none-select"
                     src={ `https://cravatar.eu/helmavatar/${player.player}/190.png` }
                     alt="Skin"
                     style={ border }/>
            </td>
            <td className="team none-select" style={ color }>{ teams[player.team].name[0] }</td>
            <td className="data name">{ player.player }</td>
            <Data value={
                (player.deaths > 0)
                    ? ((player.kills || 0) / player.deaths).toFixed(2)
                    : "0.00"
            } />
            <Data value={ player.kills || 0 } />
            <Data value={ player.deaths || 0 } />
            <Data value={ player.beds || 0 } />
            <Data value={ player.final_kills || 0 } />
            <Data value={ player.damage_taken } icon={ <AiFillHeart /> } />
            <Data value={ player.damage_dealt } icon={ <AiFillHeart /> } />
            <Data value={
                    ((player.kills - player.deaths + (player.damage_dealt * 0.1) - (player.damage_taken * 0.1)) || 0.0).toFixed(1)
                } icon={ <AiFillStar /> }
            />
        </tr>
    )
}